@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poetsen+One&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --fluid-8-10: clamp(0.5rem, 0.4082rem + 0.4082vw, 0.625rem);
  --fluid-10-14: clamp(0.625rem, 0.4413rem + 0.8163vw, 0.875rem);
  --fluid-14-18: clamp(0.875rem, 0.6913rem + 0.8163vw, 1.125rem);
  --fluid-18-32: clamp(1.125rem, 0.4821rem + 2.8571vw, 2rem);
  --fluid-40-64: clamp(2.5rem, 1.398rem + 4.898vw, 4rem);

  --page-background: #030712;
  --background: #030712;
  --inverse-background: #ffffff;
  --background-secondary: #112323;
  --text-primary: #ffffff;
  --text-secondary: #489167;
  --text-tertiary: #a1a1aa;
  --button-hover: #0c191d;
  --theme-toggle-color: #d3de3a;
}

:root[data-theme="light"] {
  --page-background: url("../src/assets/dot_bg.svg");
  --background: #ffffff;
  --inverse-background: #030712;
  --background-secondary: #305e4f;
  --text-primary: #222222;
  --text-secondary: #2e9263;
  --text-tertiary: #5b5b5c;
  --button-hover: #1f4940;
  --theme-toggle-color: #82418f;

  background-image: var(--page-background);
  background-repeat: repeat;
}

body {
  background-color: var(--page-background);
}

h1 {
  font-family: "Poetsen One", sans-serif;
  color: var(--text-primary);
  font-size: var(--fluid-40-64);
}

h2 {
  font-family: "Poetsen One", sans-serif;
  color: var(--text-secondary);
  font-size: var(--fluid-18-32);
}

p {
  font-family: "Nunito", sans-serif;
  color: var(--text-primary);
  font-size: var(--fluid-14-18);
}

a {
  font-family: "Nunito", sans-serif;
  font-size: var(--fluid-14-18);
}

button {
  font-family: "Nunito", sans-serif;
  font-size: var(--fluid-14-18);
}

button:active {
  background-color: transparent;
}

input {
  color: var(--text-primary);
  font-family: "Nunito", sans-serif;
  font-size: var(--fluid-10-14);
  transition: box-shadow 0.3s ease-out;
}

textarea {
  color: var(--text-primary);
  font-family: "Nunito", sans-serif;
  font-size: var(--fluid-10-14);
  transition: box-shadow 0.3s ease-out;
}

input:focus,
textarea:focus {
  box-shadow: 0 0 10px 0px var(--text-secondary);
}

button,
a,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  box-shadow: none;
}
